<template>
  <div class="text-sm">
    <div class="search">
      <input-text
        placeholder="Search for store"
        v-model.trim="search"
        input-id="search-input"
        input-name="search[input]"
      >
        <template #prefix>
          <ic-search title="Search" />
        </template>
      </input-text>
    </div>

    <div
      v-if="!oneLevel"
      class="header mt-2 flex items-center justify-between"
    >
      <div class="flex items-center overflow-hidden">
        <div class="mr-2 mb-1">
          <the-button
            @click="handleBack"
            :disabled="!activeItem"
            secondary
            small
          >
            <template #icon>
              <ic-chevron :size="20" />
            </template>
          </the-button>
        </div>
        <div class="font-bold truncate">
          {{ heading }}
        </div>
      </div>
      <div class="flex-shrink-0 text-xs">({{ currentItems.length }} / {{ allItemsCount }})</div>
    </div>

    <div
      class="list"
      :class="{ 'mt-4': oneLevel }"
    >
      <transition-group
        :name="transition"
        tag="div"
      >
        <GoogleStoreListItem
          v-for="item in currentItems"
          :key="`item-${item.id}`"
          :item="item"
          :one-level="oneLevel"
          :selected="value && value.id === item.id"
          @level="setItem($event)"
          @select="selectItem($event)"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import GoogleStoreListItem from '@/components/store/GoogleStoreListItem'
import IcSearch from 'vue-material-design-icons/Magnify'
import InputText from '@/components/input/InputText'
import IcChevron from 'vue-material-design-icons/ChevronLeft'

export default {
  name: 'google-store-select',
  components: {
    IcChevron,
    InputText,
    IcSearch,
    GoogleStoreListItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    oneLevel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    excludeIds: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      breadcrumbItems: [],
      search: '',
      searchItems: [],
      activeItem: null,
      transition: 'slide-left',
    }
  },
  watch: {
    search(value) {
      this.activeItem = null

      if (this.searchIsActive) {
        this.transition = 'none'
      }
    },
  },
  computed: {
    searchIsActive() {
      return this.search.length > 2
    },
    heading() {
      if (this.searchIsActive && !this.activeItem) {
        return 'Search'
      }

      return this.activeItem ? this.activeItem.name : 'Top level'
    },
    allItemsCount() {
      return Object.values(this.allItems).length
    },
    allItems() {
      let items = this.items.reduce((object, item) => {
        object[item.id] = { ...item, children: [] }

        if (item.hasOwnProperty('parent_id') && item.parent_id !== null) {
          object[item.parent_id].children.push(item.id)
        }

        return object
      }, {})

      if (!this.oneLevel) {
        /**
         * Filter out connected stores
         */
        for (let id in items) {
          if (!items.hasOwnProperty(id) || !this.excludeIds.includes(id)) continue
          delete items[id]
        }
      }

      return items
    },
    currentItems() {
      /**
       * Search
       */
      if (this.searchIsActive && !this.activeItem) {
        return Object.values(this.allItems).filter(item => {
          let searchName = `${item.name} ${item.id}`.replace('-', '')

          return searchName.toLowerCase().trim().includes(this.search.toLowerCase().trim())
        })
      }

      if (this.oneLevel) {
        return Object.values(this.allItems)
      }

      /**
       * Active item
       */

      if (this.activeItem) {
        return Object.values(this.allItems).filter(item => this.activeItem.children.includes(item.id))
      }

      /**
       * Show root
       */
      return Object.values(this.allItems).filter(item => item.parent_id === null)
    },
    breadcrumbs() {
      return this.generateBreadcrumbs(this.activeItem)
    },
  },
  methods: {
    setItem(id) {
      this.transition = 'slide-left'
      this.activeItem = this.allItems[id]
    },
    selectItem(item) {
      if (item.manager) {
        this.setItem(item.id)
        return
      }

      this.$emit('input', item)
    },
    getParentItem(item) {
      return item.parent_id !== null ? this.allItems[item.parent_id] : null
    },
    generateBreadcrumbs(item) {
      if (!item) {
        return []
      }

      let bag = []

      let parent = this.getParentItem(item)
      while (parent !== null) {
        bag.push(parent)
        parent = this.getParentItem(parent)
      }

      bag.push(item)

      return bag
    },
    handleBack() {
      if (!this.activeItem) return
      this.transition = 'slide-right'

      if (this.activeItem.parent_id === null || this.searchIsActive) {
        this.activeItem = null

        return
      }

      this.activeItem = this.allItems[this.activeItem.parent_id]
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  &:focus {
    box-shadow: none;
    transform: translate(0, 0);
  }
}

.list {
  text-align: left;
  height: 231px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;

  @include breakpoint($breakpoint__sm) {
    height: 241px;
  }
}

.breadcrumb {
  &.active {
    cursor: pointer;
    @apply text-blue-600;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
