<template>
  <base-modal>
    <template #header>
      <h3 class="h3 m-0">{{ title }}</h3>
    </template>

    <template #default>
      <div class="content">
        <transition
          tag="div"
          name="fade"
          mode="out-in"
        >
          <div
            v-if="show === CONSENT"
            key="CONSENT"
            class="just-center-it m-auto max-w-xs"
          >
            <transition
              tag="div"
              name="fade"
              mode="out-in"
            >
              <div
                v-if="assertingConsent"
                key="ASSERTING_CONSENT"
                class="just-center-it"
              >
                <div>
                  Connecting to Google
                  <punctuation-loader />
                </div>
              </div>
              <div
                v-else
                key="CONSENT_MISSING"
                class="just-center-it"
              >
                <div>We need to have your consent to access your Google Ads account.</div>
                <div class="mt-4">
                  <the-button
                    @click="handleGiveConsent"
                    primary
                  >
                    Give consent
                  </the-button>
                </div>
              </div>
            </transition>
            <transition
              name="fade"
              mode="out-in"
            >
              <div
                v-if="showConsentError"
                class="mt-4 w-full text-left"
              >
                <div class="rounded bg-red-200 text-red-600 text-xs p-3 w-full">
                  Something went wrong. Please try again or contact us at
                  <a
                    class="text-red-600 underline"
                    href="mailto:support@bidbrain.com"
                    >support@bidbrain.com</a
                  >
                </div>
              </div>
            </transition>
          </div>

          <div
            v-else-if="show === SELECT_STORE"
            key="SELECT_STORE"
          >
            <div class="text-center p1">Select the store you want to connect</div>
            <div class="mt-4">
              <GoogleStoreSelect
                :items="items"
                :exclude-ids="excludeGoogleAdsCustomerIds"
                v-model="selectedItem"
              />
            </div>
            <input-text
              label="Google account"
              input-id="google-account-name"
              input-name="google-account-name"
              :value="googleAccountName"
              placeholder="No account selected"
              disabled
              class="mt-8"
            />
          </div>

          <div
            v-else-if="show === SELECT_SUBSCRIPTION"
            key="SELECT_SUBSCRIPTION"
          >
            <div class="just-center-it">
              <span
                >Now select subscription for <em>{{ selectedItem.name }}</em></span
              >
            </div>
            <subscription-selector
              :store-id="selectedStoreId"
              :no-selected="true"
              @selected="handleSubscriptionSelected"
              class="mt-4 mb-8"
            />
            <pricing-links />
          </div>
        </transition>

        <Loader
          container
          :loading="fetchingList"
          text="Fetching stores"
          subtext="This may take a while depending on the size of your account. You may close this dialog, the progress will continue in the background."
        />
        <Loader
          container
          :loading="verifyingToken"
          text="Verifying"
        />
      </div>
    </template>

    <template #footer>
      <div class="flex justify-between">
        <div>
          <bb-loader v-if="connectingStore || updatingStore" />
          <div
            v-if="!!connectError"
            class="just-center-it text-danger"
          >
            {{ connectError }}
          </div>
          <div
            v-if="!!updateError"
            class="just-center-it text-danger"
          >
            Could not update subscription
          </div>
        </div>

        <div class="flex">
          <the-button
            v-if="show !== SELECT_SUBSCRIPTION"
            @click="negativeBtnClick"
            class="mr-4"
            secondary
          >
            {{ negativeBtnText }}
          </the-button>
          <the-button
            v-if="show === SELECT_STORE"
            @click="positiveButtonClick"
            :disabled="!selectedItem || connectingStore || updatingStore"
            primary
          >
            {{ positiveBtnText }}
          </the-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseModal from '@/components/modals/base/BaseModal'
import Loader from '@/components/loader/Loader'
import GoogleStoreSelect from '@/components/store/GoogleStoreSelect'
import googleConfig from '@/configs/google-client'
import PunctuationLoader from '@/components/loader/PunctuationLoader'
import InputText from '@/components/input/InputText'
import SubscriptionSelector from '@/components/subscriptions/SubscriptionSelector'
import BbLoader from '@/components/loader/BBLoader'
import PricingLinks from '@/components/subscriptions/PricingLinks'
import Toast from '@/components/shared/Toast'

export default {
  name: 'ConnectStoreModal',
  components: {
    PricingLinks,
    BbLoader,
    SubscriptionSelector,
    InputText,
    PunctuationLoader,
    GoogleStoreSelect,
    BaseModal,
    Loader,
    Toast,
  },
  modalProps: {
    height: 'auto',
    maxWidth: 900,
    scrollable: true,
  },
  data() {
    return {
      CONSENT: 'CONSENT',
      SELECT_STORE: 'SELECT_STORE',
      SELECT_SUBSCRIPTION: 'SELECT_SUBSCRIPTION',
      show: 'CONSENT',
      googleIsInitialized: false,
      selectedItem: null,
      items: [],
      fetchingList: false,
      verifyingToken: false,
      connectingStore: false,
      connectError: null,
      assertingConsent: true,
      showConsentError: false,
    }
  },
  computed: {
    ...mapGetters({
      excludeGoogleAdsCustomerIds: 'store/storesGoogleAdsCustomerIds',
      account: 'account/getAccount',
      updatingStoreById: 'store/isUpdatingStoreById',
      updateStoreErrorById: 'store/getUpdateStoreErrorById',
      selectedStoreId: 'store/selectedStoreId',
    }),
    title() {
      return this.show === this.SELECT_SUBSCRIPTION ? 'Store connected' : 'Connect store'
    },
    googleAccountName() {
      if (!this.selectedItem) {
        return ''
      }

      return `${this.selectedItem.name} (${this.selectedItem.readable_id})`
    },
    negativeBtnText() {
      return this.show === this.SELECT_SUBSCRIPTION ? 'Back' : 'Cancel'
    },
    positiveBtnText() {
      if (this.connectingStore) return 'Connecting'
      if (this.updatingStore) return 'Updating'
      return 'Connect'
    },
    updatingStore() {
      return this.selectedStoreId ? this.updatingStoreById(this.selectedStoreId) : false
    },
    updateError() {
      return this.selectedStoreId ? this.updateStoreErrorById(this.selectedStoreId) : false
    },
  },
  methods: {
    ...mapActions({
      createStore: 'store/createStore',
      loadCardsIfNeeded: 'card/loadCardsIfNeeded',
      loadSubscriptionsIfNeeded: 'subscription/loadSubscriptionsIfNeeded',
    }),
    verifyToken(token) {
      return this.$http.post('auth/google', { token })
    },
    async handleGiveConsent() {
      this.showConsentError = false

      try {
        let instance = await window.gapi.auth2.getAuthInstance()

        if (!instance) {
          this.showConsentError = true
          return
        }

        // Grant access to get a token to send to backend
        const response = await instance.currentUser.get().grantOfflineAccess({
          prompt: 'consent',
          response_type: 'code',
          scope: googleConfig.scopes.connectStore,
        })

        if (!response.hasOwnProperty('code')) {
          this.showConsentError = true
          return
        }

        await this.handleToken(response.code)
      } catch (e) {
        if (e.error === 'popup_closed_by_user') return

        this.showConsentError = true
      }
    },
    async handleToken(token) {
      this.verifyingToken = true

      try {
        await this.verifyToken(token)
      } catch (e) {
        this.showConsentError = true
        this.verifyingToken = false

        return
      }

      this.verifyingToken = false
      this.show = this.SELECT_STORE

      this.loadGoogleAvailableStores()
    },
    negativeBtnClick() {
      if (this.show === this.SELECT_SUBSCRIPTION) {
        this.show = this.SELECT_STORE
        this.selectedItem = null
      } else {
        if (this.$attrs.cancel) {
          this.$attrs.cancel()
        }
        this.$emit('close')
      }
    },
    positiveButtonClick() {
      this.connect()
    },
    connect() {
      if (!this.selectedItem) {
        return
      }
      this.connectError = null
      this.connectingStore = true

      this.createStore({
        name: this.selectedItem.name || this.selectedItem.id,
        currency: this.selectedItem.currency,
        google_ads_customer_id: this.selectedItem.id,
      })
        .then(() => {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'You’re done! You have successfully added a new store!',
              type: 'success',
            },
          })
          if (this.$attrs.connectedToStore) {
            this.$gtm.trackEvent({
              event: 'connect_store_success',
            })
          }
          this.$router.push({ name: 'dashboard' })
          localStorage.setItem(this.selectedStoreId, this.selectedStoreId)
          this.$emit('close')
        })
        .catch(e => {
          this.connectError = e.response.data.error ?? 'Could not connect the store'
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: this.connectError,
              type: 'error',
            },
          })
        })
        .finally(() => (this.connectingStore = false))
    },
    loadGoogleAvailableStores() {
      this.fetchingList = true
      this.showConsentError = false

      this.$http
        .get('google/account')
        .then(response => {
          this.items = response.data.data
        })
        .catch(e => {
          // When backend differs "partly missing consent" error from other errors, show it!

          /** Anyways, we need consent */
          this.showConsentError = true
          this.show = this.SELECT_STORE
        })
        .finally(() => (this.fetchingList = false))
    },
    async assertGoogleConsent() {
      this.assertingConsent = true

      let hasConsent = this.account.refresh_token

      if (!hasConsent) {
        try {
          const res = await this.$http.get(`account/${this.account.id}`)
          hasConsent = res.data.data.refresh_token
        } catch (e) {
          this.showConsentError = true
        }
      }

      this.assertingConsent = false

      return hasConsent
    },
    handleSubscriptionSelected() {
      this.$toast.success({
        component: Toast,
        props: {
          title: 'Success',
          message: 'Store added!',
          type: 'success',
        },
      })
      this.$router.push({ name: 'dashboard' })
      this.$emit('close')
    },
  },
  async beforeMount() {
    this.loadCardsIfNeeded()
    this.loadSubscriptionsIfNeeded()

    const hasConsent = await this.assertGoogleConsent()

    if (hasConsent) {
      this.loadGoogleAvailableStores()
      this.show = this.SELECT_STORE
    } else {
      this.show = this.CONSENT
    }
  },
}
</script>
<style lang="scss">
.content {
  position: relative;
}

.just-center-it {
  @apply flex text-center flex-col justify-center items-center h-full;
}
</style>
