<template>
  <div
    class="c-google-store-list-item flex"
    :class="{ selected: selected, 'one-level': oneLevel, 'is-manager': item.manager }"
  >
    <div class="c-google-store-list-item__content p3 sm:p4 flex">
      <a
        href="#"
        @click.prevent="$emit('select', item)"
        class="c-google-store-list-item__link"
      >
        <div class="c-google-store-list-item__primary">
          <div class="c-google-store-list-item__name">
            {{ item.name }}
          </div>
        </div>
        <div class="c-google-store-list-item__id">
          {{ item.readable_id }}
        </div>
      </a>
    </div>

    <div
      v-if="!oneLevel"
      class="c-google-store-list-item__children"
    >
      <button
        v-if="item.children.length > 0"
        @click="$emit('level', item.id)"
      >
        <ic-chevron :size="20" />
      </button>
    </div>
  </div>
</template>
<script>
import IcChevron from 'vue-material-design-icons/ChevronRight'

export default {
  name: 'GoogleStoreListItem',
  components: { IcChevron },
  props: {
    item: {
      type: Object,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Boolean,
      default: false,
    },
    oneLevel: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.c-google-store-list-item {
  $self: &;
  $hoverBackgroundColor: rgba(#7700ff, 0.1);

  position: relative;
  display: flex;
  align-items: center;

  & + & {
    border-top: 1px solid #f5f5f5;
  }

  &.selected {
    background-color: #ddd0f8;
    #{$self}__name,
    #{$self}__id {
      font-weight: 600;
    }

    #{$self}_link {
      pointer-events: none;
    }
  }

  transition: background-color 0.2s;
  background-color: #fff;

  &:hover {
    background-color: $hoverBackgroundColor;
    opacity: 1;
  }

  &.one-level {
    #{$self}__content {
      padding-right: 0;
    }
  }

  &__content {
    width: 100%;
  }

  &__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px 13px 5px;
    color: inherit;
  }

  &__children {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
  }

  &__primary {
    overflow-x: hidden;
    cursor: pointer;
    display: block;
    flex-grow: 1;
    height: 100%;
  }

  &__name {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  &__id {
    line-height: 1;
    flex-shrink: 0;
  }
}
</style>
