<template>
  <span class="punctuation">
    {{ this.loading ? punctuation : null }}
  </span>
</template>
<script>
export default {
  name: 'punctuation-loader',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      count: 0,
      timer: null,
    }
  },
  computed: {
    punctuation() {
      let punctuation = []
      for (let i = 0; i < this.count; i++) {
        punctuation.push('.')
      }
      return punctuation.join(' ')
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.count = this.count === 3 ? 0 : this.count + 1
    }, 500)
  },
  destroyed() {
    clearInterval(this.timer)
  },
}
</script>
<style scoped>
.punctuation {
  width: 20px;
  display: inline-block;
  text-align: left;
}
</style>
